/* Use this file to define print styles.

//Force backgroud images/color
/* * {
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}*/
* {
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}

.bk-quote,
.wrapper-text,
.hero-title,
.date,
.title,
.js-summary li,
.bk-mention,
.img-wrapper,
.text-wrapper,
li {
  opacity: 1 !important;
  transform: translate(0px, 0px) !important;
}

.bk-video-wrapper,
.module-search {
  display: none !important;
}

.page-break-before {
  page-break-before: always;
}

.bk-discover {
  color: black !important;
}
.bk-discover .title-w-curve {
  color: black !important;
}

.hero-title,
.intro,
.date {
  color: black !important;
}

.bottom-hero {
  background-color: white;
}

.hero-edito.bg-color,
.hero-xl.bg-color {
  background-color: white !important;
}

header,
footer,
.btn-back-to-top,
.bk-floatings-btns {
  display: none !important;
}

.global-wrapper {
  padding-top: 0 !important;
}

.page-content-wrapper .site-content.-main {
  padding-top: 20px !important;
}

/*ACCORDION*/
.bk-accordion {
  display: block !important;
}
.bk-accordion .accordion-content {
  display: block !important;
}

.bk-stepper .stepper-list .stepper-step .step-title,
.bk-stepper .stepper-list .stepper-step .step-number {
  font-size: 14px !important;
}
.bk-stepper .stepper-list .stepper-step .step-number {
  padding: 18px 10px !important;
}

.bk-desc-offer .offer-title {
  display: block !important;
}
.bk-desc-offer .offer-title img {
  display: block !important;
  margin: 0 auto !important;
}

.bk-contact-help .grid,
.bk-accordion .grid,
bk-news-event .grid {
  display: block !important;
}
.bk-contact-help .grid .grid__cell,
.bk-accordion .grid .grid__cell,
bk-news-event .grid .grid__cell {
  width: 100% !important;
}

.bk-contact-banner {
  color: #000 !important;
}

.styled-select:before, .styled-select:after {
  display: none !important;
}
.styled-select:before select, .styled-select:after select {
  appearance: button !important;
}

/*SLIDER*/
.slick-track {
  width: 100% !important;
  transform: translate3d(0, 0, 0) !important;
}

.slick-slide {
  width: 100% !important;
}
.slick-slide.slick-cloned {
  display: none !important;
}

.slick-arrow,
.slick-dots {
  display: none !important;
}

.bk-top-slider .slider-wrapper .slider-slide {
  padding: 0 !important;
}
.bk-top-slider .slider-wrapper .slider-slide .img-wrapper {
  position: relative !important;
}

.bk-focus {
  display: block !important;
}
.bk-focus .img-wrapper {
  width: 100% !important;
}
.bk-focus .text-wrapper {
  padding-left: 0 !important;
}

.bk-app .of-cover {
  position: relative !important;
}

.bk-img-text .-flex-mode {
  display: block !important;
}

.table-responsive {
  overflow: hidden !important;
}

.custom-table tr td {
  min-width: inherit !important;
  max-width: 100% !important;
}
.custom-table tr td .td-text {
  max-height: inherit !important;
  padding-right: 0 !important;
  overflow: auto !important;
}

.norme-detail h1 {
  font-size: 24px !important;
}
.norme-detail h2 {
  font-size: 22px !important;
}
.norme-detail h3 {
  font-size: 21px !important;
}
.norme-detail h4 {
  font-size: 20px !important;
}